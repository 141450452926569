.navbar {
  background: #000; }

.navbar-brand {
  color: #ffffff;
  font-family: Futura;
  text-transform: uppercase; }

.archetype-title {
  justify-content: center; }

.archtype-card-row {
  justify-content: center;
  text-align: center; }

.archtype-card-right {
  justify-content: end;
  text-align: center;
  padding: 15px 0px; }

.archtype-card {
  border: none; }

.archtype-light-mixed {
  border-image: linear-gradient(#fdbf2d, #797878);
  border-image-slice: 1; }

.archtype-card-img {
  max-width: 100%; }

.archtype-letter {
  font-family: ProximaNova;
  font-size: 66px; }

.archtype-description {
  font-family: ProximaNova;
  color: #b3b3b3;
  height: 60px; }

.archtype-separator {
  border: none;
  height: 2px;
  background-color: #fdbf2d;
  color: #fdbf2d; }

.pdf-button {
  background-image: linear-gradient(#0392D6, #7A55B9);
  border: none;
  color: #f2f2f2;
  padding-left: 25px;
  padding-right: 25px; }

.sphinx-card-row {
  justify-content: center;
  text-align: center;
  padding: 15px 0px; }

.sphinx-card-right {
  justify-content: end;
  text-align: center;
  padding: 15px 0px; }

.sphinx-card {
  border-radius: 0px;
  border: 2px solid;
  width: 100%;
  padding-top: 158%; }

.sphinx-light {
  border-color: #fdbf2d; }

.sphinx-light-mixed {
  border-image: linear-gradient(#fdbf2d, #797878);
  border-image-slice: 1; }

.sphinx-dark {
  border-color: #797878; }

.sphinx-card-empty {
  border: 2px none;
  background: none;
  width: 100%;
  padding-top: 158%; }

.sphinx-card-img {
  width: 25vh; }

.sphinx-letter {
  font-family: ProximaNova;
  font-size: 66px; }

@font-face {
  font-family: "ProximaNova";
  src: url(/static/media/ProximaNova-Regular.410504d4.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNovaLight";
  src: url(/static/media/ProximaNova-Alt-Light.e2602b2f.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: url(/static/media/ProximaNova-Bold.62d4d7d3.otf) format("opentype");
}

@font-face {
  font-family: "Futura";
  src: url(/static/media/Futura-Regular.ee64fb9d.ttf) format("truetype");
}

@font-face {
  font-family: "FuturaBold";
  src: url(/static/media/Futura-Bold.3ea9695e.ttf) format("truetype");
}

body {
  font-size: 1.5rem;
  line-height: 1.25;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

p {
  font-family: ProximaNovaLight;
}

label {
  font-weight: 300;
}

h1 {
  font-family: FuturaBold;
  font-weight: bold;
  color: #060606;
}

h2 {
  font-family: ProximaNovaLight;
  text-transform: uppercase;
  font-size: 400%;
  letter-spacing: 4px;
}

h3 {
  font-family: Futura;
}

h5 {
  font-family: ProximaNovaLight;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

hr {
  border: none;
  height: 2px;
  background-color: #bfbfbf;
  color: #bfbfbf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.blueprint-input {
  margin-top: 6px;
}

.page-break {
  display: none;
}

.white-background {
  background: white;
  display: flex;
  align-items: center;
}

.sphinx-background {
  background-image: url(/static/media/cover.7a657990.jpg);
  /* Full height */

  /* Center and scale the image nicely */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.sphinx-background-dark {
  background-color: #000c18;
  /* Full height */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  align-items: center;
}

.sphinx-intro {
  color: #fff;
}

.sphinx-blueprint {
  font-size: 135%;
  margin-bottom: 25px;
}

.destiny-form-label {
  font-size: 1.35rem;
}

.scroll-snap {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-destination: 0% 100%;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

.scroll-snap-child {
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
}

.scroll-snap-child-cards {
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
}

.logo-bottom {
  position: absolute;
  bottom: 20px;
  height: 50px;
  left: 50%;
}

.logo-sm {
  width: 60px;
  margin-left: -30px;
}

.month {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-confirm {
  background: #fff;
  color: #495057;
  width: 140px;
}

.form-control {
  border: none;
  border-radius: 0;
  box-shadow: inset 1px 1px 3px #000;
}

.btn {
  border-radius: 0;
}

.card-title {
  text-transform: uppercase;
  font-family: ProximaNova;
  letter-spacing: 1px;
  font-size: 150%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.archtype-title {
  font-family: ProximaNova;
  font-size: 250%;
  letter-spacing: 1px;
}

.arctype-description {
  font-size: 85%;
  color: #888;
}

.keyword-title {
  text-transform: uppercase;
  font-style: italic;
  font-family: ProximaNovaLight;
  letter-spacing: 1.5px;
  color: #888;
  font-size: 85%;
}

.dob {
  font-size: 80%;
  color: #b3b3b3;
}

.dob p {
  margin-bottom: 0px;
}

.keywords {
  font-family: ProximaNova;
  font-weight: bold;
  color: #888;
  font-size: 85%;
}

.ego-map-img {
  max-width: 50%;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.sphinx-journey {
  top: calc(50% - 110px);
  left: calc(50% - 150px);
  position: absolute;
}

.logo-top {
  opacity: 0;
  -webkit-animation: logo-anim 2s infinite alternate;
  animation: logo-anim 2s infinite alternate;
}

.arrow-bottom {
  margin-left: -60px;
  opacity: 1;
  -webkit-animation: anim 2s infinite alternate;
  animation: anim 2s infinite alternate;
}

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-perspective: 800px;
          perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  -webkit-animation: rotate-one 1s linear infinite;
          animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #efeffa;
}

.inner.two {
  right: 0%;
  top: 0%;
  -webkit-animation: rotate-two 1s linear infinite;
          animation: rotate-two 1s linear infinite;
  border-right: 3px solid #efeffa;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: rotate-three 1s linear infinite;
          animation: rotate-three 1s linear infinite;
  border-top: 3px solid #efeffa;
}

@-webkit-keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-webkit-keyframes anim {
  0% {
    opacity: 0;
  }
  36.36% {
    opacity: 0;
  }
  63.63% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anim {
  0% {
    opacity: 0;
  }
  36.36% {
    opacity: 0;
  }
  63.63% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes logo-anim {
  0% {
    opacity: 1;
  }
  36.36% {
    opacity: 1;
  }
  63.63% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logo-anim {
  0% {
    opacity: 1;
  }
  36.36% {
    opacity: 1;
  }
  63.63% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media print {
  body {
    overflow: hidden;
  }

  .pdf-button {
    display: none;
  }

  .scroll-snap {
    overflow: hidden;
    height: auto;
    scroll-snap-type: none;
  }

  scroll-snap-child-cards {
    height: auto;
    min-height: 100vh;
    scroll-snap-align: none;
    page-break-before: always;
  }
}

@media only screen and (max-width: 1900px) {
  .dob {
    font-size: 70%;
  }

  .sphinx-container {
    max-width: 800px;
  }

  .archtype-card-row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sphinx-title {
    font-size: 60%;
  }

  .archtype-title {
    font-size: 150%;
  }

  .arctype-description {
    font-size: 65%;
    margin-bottom: 10px;
  }

  .scroll-snap-child-cards {
    height: 100vh;
    position: relative;
    scroll-snap-align: center;
  }

  .card-title {
    font-size: 105%;
  }

  .keyword-title {
    font-size: 65%;
  }

  .keywords {
    font-size: 65%;
  }

  .ego-map-img {
    max-width: 40%;
  }
}

@media only screen and (max-width: 992px) {
  .dob {
    font-size: 90%;
  }

  .sphinx-title {
    font-size: 90%;
  }

  .archtype-title {
    font-size: 180%;
  }

  .arctype-description {
    font-size: 80%;
  }

  .scroll-snap-child-cards {
    height: 100vh;
    position: relative;
    scroll-snap-align: center;
  }

  .card-title {
    font-size: 90%;
  }

  .keyword-title {
    font-size: 80%;
  }

  .keywords {
    font-size: 80%;
  }
}

@media only screen and (max-width: 576px) {
  h2 {
    font-size: 300%;
  }

  .sphinx-blueprint {
    font-size: 80%;
  }

  .logo-bottom {
    bottom: 50px;
  }

  .destiny-form {
    font-size: 70%;
  }

  .destiny-form input {
    font-size: 70%;
  }

  .destiny-form select {
    font-size: 70%;
  }

  .archtype-title {
    font-size: 100%;
  }

  .arctype-description {
    font-size: 70%;
  }

  .dob {
    font-size: 70%;
  }

  .sphinx-title {
    font-size: 70%;
  }

  .sphinx-card-img {
    max-width: 100%;
  }

  .ego-map-img {
    max-width: 90%;
  }

  .scroll-snap-child-cards {
    margin-top: 50px;
    margin-bottom: 50px;
    height: auto;
    min-height: 100vh;
    position: relative;
    scroll-snap-align: none;
  }

  .card-title {
    font-size: 80%;
  }

  .keyword-title {
    font-size: 60%;
  }

  .keywords {
    font-size: 60%;
  }
}

