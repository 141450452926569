.sphinx-card-row {
  justify-content: center;
  text-align: center;
  padding: 15px 0px;
}

.sphinx-card-right {
  justify-content: end;
  text-align: center;
  padding: 15px 0px;
}

.sphinx-card {
  border-radius: 0px;
  border: 2px solid;
  width: 100%;
  padding-top: 158%;
}

.sphinx-light {
  border-color: #fdbf2d;
}

.sphinx-light-mixed {
  border-image: linear-gradient(#fdbf2d, #797878);
  border-image-slice: 1;
}

.sphinx-dark {
  border-color: #797878;
}

.sphinx-card-empty {
  border: 2px none;
  background: none;
  width: 100%;
  padding-top: 158%;
}

.sphinx-card-img {
  width: 25vh;
}

.sphinx-letter {
  font-family: ProximaNova;
  font-size: 66px;
}
