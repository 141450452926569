.archetype-title {
  justify-content: center;
}

.archtype-card-row {
  justify-content: center;
  text-align: center;
}

.archtype-card-right {
  justify-content: end;
  text-align: center;
  padding: 15px 0px;
}

.archtype-card {
  border: none;
}

.archtype-light-mixed {
  border-image: linear-gradient(#fdbf2d, #797878);
  border-image-slice: 1;
}

.archtype-card-img {
  max-width: 100%;
}

.archtype-letter {
  font-family: ProximaNova;
  font-size: 66px;
}

.archtype-description {
  font-family: ProximaNova;
  color: #b3b3b3;
  height: 60px;
}

.archtype-separator {
  border: none;
  height: 2px;
  background-color: #fdbf2d;
  color: #fdbf2d;
}
